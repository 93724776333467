<template>
  <v-col cols="12">
    <v-row>
      <v-col cols="12">
        <data-table
          :headers="headers"
          :items="arItems"
          :options="pagination"
          :total="serverItemsLength"
          :last-page="serverLastPage"
          :loading="bLocalLoading"
          btn-action-path="taxtypes"
          btn-action-item-key-id="id"
          hide-btn-action-view
          is-route-name
          @delete="deleteItem"
          @update:sort-by="onSortBy"
          @update:sort-desc="onSortDesc"
          @update:options="onPagination"
          @update:page="onPage"
        >
          <template #[`item.name`]="{ item }">
            <v-btn
              :to="{ name: 'taxtypes.update', params: { id: item.id } }"
              text
              color="primary"
            >
              {{ item.name }}
            </v-btn>
          </template>

          <template #body="data">
            <draggable
              v-model="arItems"
              tag="tbody"
              handle=".v-btn.handler"
              @end="onReorder"
            >
              <tr
                v-for="(item, index) in data.items"
                :key="`taxtypes.row.${index}`"
              >
                <template v-for="(col, idx) in data.headers">
                  <td :key="`taxtypes.col.${index}.${idx}`">
                    <v-btn
                      text
                      icon
                      class="handler"
                      v-if="col.value == 'reorder'"
                    >
                      <icon-menu size="1.5em" />
                    </v-btn>
                    <v-btn
                      :to="{ name: 'taxtypes.update', params: { id: item.id } }"
                      text
                      color="primary"
                      v-else-if="col.value == 'name'"
                    >
                      {{ item.name }}
                    </v-btn>

                    <module-actions
                      v-else-if="col.value == 'actions'"
                      :value="item.id"
                      @delete="deleteItem"
                    />

                    <span v-else>{{ $_.get(item, col.value) }}</span>
                  </td>
                </template>
              </tr>
            </draggable>
          </template>
        </data-table>
      </v-col>
    </v-row>
    <taxtypes-form
      v-model="obItem"
      :open.sync="displayForm"
      :key="$route.params.id"
    />
  </v-col>
</template>

<script lang="ts">
import { Mixins, Component } from "vue-property-decorator";
import TaxtypesMixin from "@/modules/taxtypes/mixins/TaxtypesMixin";
import TaxtypesForm from "@/modules/taxtypes/components/Form.vue";
import Draggable from "vuedraggable";
import { TaxTypeCollection, TaxTypeData } from "@planetadeleste/vue-mc-gw";
import { DataTableHeader } from "@/mixins/DataTableMixin";

@Component({
  components: { TaxtypesForm, Draggable },
})
export default class TaxtypesList extends Mixins(TaxtypesMixin) {
  isList = true;
  arItems: TaxTypeData[] | Record<string, any>[] = [];

  onMounted() {
    const arHeaders: DataTableHeader[] = [
      { text: "order", value: "reorder", sortOrder: 1 },
      { text: "description", value: "description" },
      { text: "code", value: "code" },
      { text: "value", value: "percent" },
    ];
    this.delDTHeader("active").addDTHeaders(arHeaders);

    this.index();
  }

  onAfterIndex() {
    // @ts-ignore
    this.arItems = this.obCollection.getModelList();
  }

  async onReorder() {
    const arTaxTypeListId: number[] = this.$_.map(this.arItems, "id");
    if (!arTaxTypeListId.length) {
      return;
    }

    const obCollection = new TaxTypeCollection();
    await obCollection.reorder(arTaxTypeListId);
  }
}
</script>
